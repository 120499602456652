
.splash {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: url(./markus-spiske-0376tfLb89c-unsplash.jpg);
  background-size: cover;
}

.splash__title {

  margin: 30px 0;

  color: #e5e2d1;
  font-family: ArchitectsDaughter-Regular;
  font-size: 10.5vw;
  text-shadow: 4px 5px 2px #3e2c20;
}

.splash>.btn {
  color: #e5e2d1;
  font-size: 24px;
}
